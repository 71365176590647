:root {
  --mdc-theme-primary: #58a3fe !important;
  --mdc-theme-secondary: #5968e2 !important;
  --mdc-theme-light: #ddd; }

html {
  overflow-y: scroll; }

html,
body {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fafafa;
  height: 100%; }

.mdc-text-field--upgraded {
  align-items: center !important;
  margin: 0 !important; }

.mdc-button {
  height: 40px !important;
  width: 100%;
  max-width: 500px; }

.mdc-button--raised {
  border-radius: 4px !important;
  background-image: linear-gradient(135deg, #6967d8 0%, #58a4ff 100%);
  box-shadow: 0 24px 48px rgba(105, 105, 215, 0.48) !important; }

.mdc-button--unelevated {
  border-radius: 4px !important;
  background-image: linear-gradient(135deg, #6967d8 0%, #58a4ff 100%); }

.mdc-button--dense {
  border-radius: 4px !important;
  background-color: #ededed !important;
  border: none !important; }

.color-gradient {
  background-image: linear-gradient(135deg, #6967d8 0%, #58a4ff 100%); }

.mdc-button--full {
  width: 100%; }

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

a:visited,
a,
a:hover {
  color: var(--mdc-theme-primary); }
